import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'input', 'output' ]

  connect() {
    this.updateColor()
  }

  updateColor() {
    this.outputTarget.innerHTML = this.inputTarget.value
  }

  onColorChange() {
    this.updateColor()
  }
}