import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['skipButton'];

  onClick() {
    setTimeout(() => {
      $(this.skipButtonTarget).removeClass('main-text-color').addClass('hero-button hero-button--inverse hero-button--small');
    }, 1000)
  }
}
