import { Controller } from 'stimulus'

export default class extends Controller {

  connect() {
    if (navigator.canShare) {
      this.element.classList.remove('hidden')
    }
  }

  onClick(e) {
    e.preventDefault()

    navigator.share({
      title: this.data.get('title'),
      url: this.data.get('url'),
    })
  }
}
