import { Controller } from 'stimulus'
import intlTelInput from 'intl-tel-input'
import _ from 'lodash'

export default class extends Controller {
  static targets = [
    'phoneNumber',
    'phoneNumberInput',
    'phoneNumberCountry',
    'phoneNumberError',
    'emailInput'
  ]

  connect() {
    if (this.hasPhoneNumberInputTarget) {
      this.iti = intlTelInput(this.phoneNumberInputTarget, {
        nationalMode: true,
        utilsScript: require('intl-tel-input/build/js/utils.js'),
        initialCountry: this.data.get('phone-number-initial-country') || 'us'
      })
      this.phoneNumberCountryTarget.value = this.iti.getSelectedCountryData().iso2
      this.phoneNumberTarget.value = this.iti.getNumber()
    }
  }

  keyup() {
    this.phoneNumberCountryTarget.value = this.iti.getSelectedCountryData().iso2
    this.phoneNumberTarget.value = this.iti.getNumber()
  }

  onSubmit(e) {
    if (this.hasPhoneNumberInputTarget) {
      const phoneNumber = this.iti.getNumber()

      if (!_.isEmpty(phoneNumber) && !this.iti.isValidNumber()) {
        e.preventDefault()
        $(this.phoneNumberErrorTarget).show()
        return
      } else {
        $(this.phoneNumberErrorTarget).hide()
      }
    }

    if (!this.emailInputTarget.validity.typeMismatch) {
      $('#stayfi-waiting-modal').modal('show')
    }
  }
}
