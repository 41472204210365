import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'passwordInput', 'showIcon', 'hideIcon' ]

  toggle(e) {
    if (this.isHidden) {
      this.passwordInputTarget.type = 'text'
      if (this.hasShowIconTarget && this.hasHideIconTarget) {
        this.showIconTarget.style.display = 'none'
        this.hideIconTarget.style.display = 'block'
      }
    } else {
      this.passwordInputTarget.type = 'password'
      if (this.hasShowIconTarget && this.hasHideIconTarget) {
        this.showIconTarget.style.display = 'block'
        this.hideIconTarget.style.display = 'none'
      }
    }
  }

  get isHidden() {
    return this.passwordInputTarget.type === 'password'
  }
}
