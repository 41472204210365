import { Controller } from 'stimulus'
import { eventTracker } from '../../utilities/guest'

export default class extends Controller {
  onTileClick(e) {
    if (this.data.get('track-events') === 'true') {
      eventTracker.track('home_page_tile.clicked', Object.assign(this.tileProperties(e), this.defaultProperties(), this.homePageId()))
    }
  }

  tileProperties(e) {
    return Object.assign(
      {},
      { tile_type: e.currentTarget.dataset.tileType },
      (e.currentTarget.dataset.tileId ? { tile_id: e.currentTarget.dataset.tileId } : {}),
      (e.currentTarget.dataset.displayName ? { display_name: e.currentTarget.dataset.displayName } : {}),
    )
  }

  defaultProperties() {
    return {
      source: this.data.get('localized-source'),
      action: 'clicked',
    }
  }

  homePageId() {
    if (this.data.has('home-page-property-id')) {
      return { home_page_property_id: this.data.get('home-page-property-id') }
    } else {
      return { home_page_template_id: this.data.get('home-page-template-id') }
    }
  }
}
